// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

import "@popperjs/core/dist/cjs/popper"

import "jquery"
import "bootstrap"

import ColorHash from 'color-hash'
window.ColorHash = ColorHash

window.bootstrap = bootstrap

import "@fortawesome/fontawesome-free/css/all"
import { Datepicker, DateRangePicker } from "vanillajs-datepicker"

import {
  Chart,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
} from "chart.js"

Chart.register(
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip
)

window.chart = Chart
Rails.start()
ActiveStorage.start()


document.addEventListener("DOMContentLoaded", function(event) {
  var datepickers = [].slice.call(document.querySelectorAll('[data-datepicker]'))
  var datepickersList = datepickers.map(function (el) {
    return new Datepicker(el, {
      buttonClass: 'btn',
      format: 'yyyy-mm-dd'
    });
  })

  var datepickerRanges = [].slice.call(document.querySelectorAll('[data-datepicker-range]'))
  var datepickerRangesList = datepickerRanges.map(function (el) {
    return new DateRangePicker(el, {
      buttonClass: 'btn',
      format: 'yyyy-mm-dd'
    });
  })
});
